// import $ from 'jquery';
// import 'slick-carousel/slick/slick';
// import axios from 'axios';
//
// let
//     initCarouselHeure = () => {
//         let startSlider = document.querySelector('.bloc-heure.active')?.dataset.index || 0;
//         let options = {
//             infinite: false,
//             slidesToShow: 5,
//             slidesToScroll: 1,
//             initialSlide: startSlider - 0,
//             vertical: true,
//             verticalSwiping: true,
//             swipeToSlide: true,
//             mobileFirst: true,
//             responsive: [
//                 {
//                     breakpoint: 576,
//                     settings: {
//                         vertical: false,
//                         verticalSwiping: false,
//                         slidesToShow: 6,
//                         initialSlide: startSlider - 0
//                     }
//                 },
//                 {
//                     breakpoint: 768,
//                     settings: {
//                         slidesToShow: 6,
//                         vertical: false,
//                         verticalSwiping: false,
//                         initialSlide: startSlider - 0
//                     }
//                 },
//                 {
//                     breakpoint: 992,
//                     settings: {
//                         slidesToShow: 12,
//                         vertical: false,
//                         verticalSwiping: false,
//                         initialSlide: startSlider - 0
//                     }
//                 },
//                 {
//                     breakpoint: 1200,
//                     settings: {
//                         slidesToShow: 24,
//                         vertical: false,
//                         verticalSwiping: false,
//                         initialSlide: startSlider - 0,
//                         swipeToSlide: true
//                     }
//                 }
//             ]
//         }
//         document.querySelectorAll('.container-heure').forEach(el => $(el).slick(options))
//     },
//     loadAjaxVoyants = () => {
//         let options = {
//             slidesToShow: 4,
//             slidesToScroll: 4,
//             draggable: false,
//             mobileFirst: true,
//             arrows: false,
//             dots: true,
//             autoplay: true,
//             autoplaySpeed: 4000,
//             responsive: [
//                 {
//                     breakpoint: 1900,
//                     settings: {slidesToShow: 4, slidesToScroll: 4}
//                 },
//                 {
//                     breakpoint: 1280,
//                     settings: {slidesToShow: 3, slidesToScroll: 3}
//                 },
//                 {
//                     breakpoint: 991,
//                     settings: {slidesToShow: 2, slidesToScroll: 2}
//                 },
//                 {
//                     breakpoint: 767,
//                     settings: {slidesToShow: 2, slidesToScroll: 2}
//                 },
//                 {
//                     breakpoint: 300,
//                     settings: {slidesToShow: 1, slidesToScroll: 1}
//                 }
//             ]
//         }
//         document.querySelectorAll('.bloc-voyants[data-ajax-listing-voyant-url]').forEach(el => {
//             axios.get(el.dataset.ajaxListingVoyantUrl).then(response => {
//                 el.innerHTML = response.data;
//                 if ('ajaxListingAnimated' in el.dataset) {
//                     if (el.slick !== undefined) $(el).slick('unslick');
//                     $(el).slick(options);
//                 }
//             })
//         })
//         setTimeout(loadAjaxVoyants, 120000);
//     }
//
// document.addEventListener('DOMContentLoaded', () => {
//     loadAjaxVoyants()
//     initCarouselHeure()
// });
import $ from 'jquery';
import 'slick-carousel/slick/slick';
import axios from 'axios';


let
    initCarouselHeure = () => {
        let startSlider = document.querySelector('.bloc-heure.active')?.dataset.index || 0;
        let options = {
            focusOnSelect: true,
            infinite: false,
            slidesToShow: 5,
            loop: false,
            slidesToScroll: 1,
            initialSlide: startSlider - 0,
            vertical: true,
            verticalSwiping: true,
            swipeToSlide: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        vertical: false,
                        verticalSwiping: false,
                        slidesToShow: 6,
                        initialSlide: startSlider - 0
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 6,
                        vertical: false,
                        verticalSwiping: false,
                        initialSlide: startSlider - 0
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 12,
                        vertical: false,
                        verticalSwiping: false,
                        initialSlide: startSlider - 0
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 16,
                        vertical: false,
                        verticalSwiping: false,
                        initialSlide: startSlider - 0
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 18,
                        vertical: false,
                        verticalSwiping: false,
                        initialSlide: startSlider - 0,
                        swipeToSlide: true
                    }
                }
            ]
        }
        document.querySelectorAll('.container-heure').forEach(el => $(el).slick(options))
    },
    loadAjaxVoyants = () => {
        let options = {
            slidesToShow: 4,
            slidesToScroll: 4,
            draggable: false,
            mobileFirst: true,
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1399,
                    settings: {slidesToShow: 4, slidesToScroll: 4}
                },
                {
                    breakpoint: 991,
                    settings: {slidesToShow: 3, slidesToScroll: 3}
                },
                {
                    breakpoint: 767,
                    settings: {slidesToShow: 2, slidesToScroll: 2}
                },
                {
                    breakpoint: 0,
                    settings: {slidesToShow: 1, slidesToScroll: 1}
                }
            ]
        //
        //     loop:true,
        //         margin:10,
        //     nav:true,
        //     responsive:{
        //     0:{
        //         items:1
        //     },
        //     600:{
        //         items:3
        //     },
        //     1000:{
        //         items:5
        //     }
        // }

        }
        document.querySelectorAll('.bloc-voyants[data-ajax-listing-voyant-url]').forEach(el => {
            axios.get(el.dataset.ajaxListingVoyantUrl).then(response => {
                el.innerHTML = response.data;
                if ('ajaxListingAnimated' in el.dataset) {
                    if (el.slick !== undefined) $(el).slick('unslick');
                    $(el).slick(options);
                }
            })
        })
        setTimeout(loadAjaxVoyants, 120000);
    }

document.addEventListener('DOMContentLoaded', () => {
    loadAjaxVoyants()
    initCarouselHeure()
});